import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  inject,
  Injector,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { CartService } from './pages/cart-page/cart.service';
import { SpreeService } from './services/spree-client/storefront/spree.service';
import { StateService } from './services/state.service';
import { storeSerializer } from './services/store-serializer';
import { filter, map } from 'rxjs';
import { LocalStorageService } from './services/local-storage.service';
import { LanguagePopupService } from './components/language-popup/language-popup.service';
import { VERSION } from './app.version';
import { SyneriseService } from './services/synerise.service';
import { CookiebotService } from './services/cookiebot.service';

const luigisBoxUrls = {
  en: 'https://scripts.luigisbox.com/LBX-576475.js',
  sk: 'https://scripts.luigisbox.com/LBX-577888.js',
  cs: 'https://scripts.luigisbox.com/LBX-578586.js',
} as Record<string, string>;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  private readonly cart = inject(CartService);
  private readonly client = inject(SpreeService);
  private readonly state = inject(StateService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly languagePopupService = inject(LanguagePopupService);
  private readonly synerise = inject(SyneriseService);
  private readonly injector = inject(Injector);
  private readonly cookiebot = inject(CookiebotService);

  // todo refactor
  ngOnInit() {
    this.state.activeLocale$.pipe(filter(Boolean)).subscribe((locale) => {
      this.updateStore();

      const cookiebotScript = this.document.getElementById('Cookiebot');
      const luigisBoxScript = this.document.getElementById('LuigisBox');
      this.document.documentElement.lang = locale;
      cookiebotScript!.setAttribute('data-culture', locale);
      luigisBoxScript!.setAttribute('src', luigisBoxUrls[locale] ?? luigisBoxUrls['en']);
    });

    afterNextRender(
      {
        read: () => {
          console.log('Version: ', VERSION);
          this.cookiebot.afterUserInitialChoice().subscribe(this.openPopup);
          this.cart.getCart();
          this.handleChunkLoadingError();
          this.synerise.init();
        },
      },
      { injector: this.injector },
    );
  }

  private openPopup = (): void => {
    this.languagePopupService.openPopup();
  };

  private updateStore(): void {
    this.client.store
      .show()
      .pipe(map((storeDto) => storeSerializer(storeDto)))
      .subscribe((store) => {
        this.state.setStore(store);
      });
  }

  private handleChunkLoadingError(): void {
    const recoverUrl = this.localStorage.getItem('chunkLoadErrorReloadTarget');

    if (!recoverUrl) {
      return;
    }

    let count = Number.parseInt(this.localStorage.getItem('chunkLoadErrorReloadCount')!);

    if (count > 1) {
      // count > 1 means previous recovery didn't work out
      this.clearChunkLoadingErrorData();

      return;
    }

    count++;
    this.localStorage.setItem('chunkLoadErrorReloadCount', count.toString());
    this.router.navigateByUrl(recoverUrl).then((isSuccessful) => {
      if (isSuccessful) {
        this.clearChunkLoadingErrorData();
      }
    });
  }

  private clearChunkLoadingErrorData(): void {
    this.localStorage.removeItem('chunkLoadErrorReloadTarget');
    this.localStorage.removeItem('chunkLoadErrorReloadCount');
  }
}
