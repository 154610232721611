import { TranslocoService } from '@jsverse/transloco';
import { APP_INITIALIZER, FactoryProvider, InjectionToken } from '@angular/core';
import { map, Observable } from 'rxjs';
import { StateService } from '../services/state.service';
import { SpreeService } from '../services/spree-client/storefront/spree.service';
import { storeSerializer } from '../services/store-serializer';
import { Location } from '@angular/common';
import { LocaleMap } from '../helpers/locale-map';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import * as Sentry from '@sentry/angular';
import { environment } from '../../environments/environment';
import { switchMap } from 'rxjs/operators';

function initializerFactory(
  locale: string,
  client: SpreeService,
  state: StateService,
  transloco: TranslocoService,
): () => Observable<unknown> {
  return () => {
    const activeCurrency = LocaleMap[locale].currency;
    state.setActiveLocale(locale);
    state.setActiveCurrency(activeCurrency);

    return client.store.show().pipe(
      map((storeDto) => storeSerializer(storeDto)),
      switchMap((store) => {
        state.setStore(store);
        transloco.setDefaultLang(store.defaultLocale);
        transloco.setAvailableLangs(store.supportedLocales);
        transloco.setActiveLang(locale);

        return transloco.load(locale);
      }),
    );
  };
}

function getLocaleFromUrl(url: string): string | null {
  if (!url) {
    return null;
  }

  const parts = url.split('/');

  if (parts[1]) {
    return parts[1];
  } else {
    return null;
  }
}

function getInitialLocale(cookies: SsrCookieService, location: Location): string {
  const apis = environment.apiUrls;
  const savedLocale = cookies.get('locale') ?? '';
  const urlLocale = getLocaleFromUrl(location.path()) ?? '';

  if (apis.has(urlLocale)) {
    return urlLocale;
  }

  if (apis.has(savedLocale)) {
    return savedLocale;
  }

  return environment.defaultLocale;
}

export const LOCALE_AND_URL_INITIALIZER = new InjectionToken<string>('apiUrlInitializer');
export const LOCALE_AND_URL_INITIALIZER_PROVIDER: FactoryProvider = {
  provide: LOCALE_AND_URL_INITIALIZER,
  deps: [SsrCookieService, Location],
  useFactory: (cookie: SsrCookieService, location: Location) => {
    const locale = getInitialLocale(cookie, location);
    environment.apiUrl = environment.apiUrls.get(locale) as string;

    return locale;
  },
};

export const APP_INITIALIZER_PROVIDER = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [
    LOCALE_AND_URL_INITIALIZER,
    SpreeService,
    StateService,
    TranslocoService,
    Sentry.TraceService,
  ],
  useFactory: initializerFactory,
};
