import { IStore } from './spree-client/storefront/interfaces/Store';
import { SvgIcons } from '@ngneat/svg-icon';

export function storeSerializer({ data: { attributes } }: IStore): Store {
  const socialMedia: Store['socialMedia'] = [];
  attributes.instagram &&
    socialMedia.push({
      name: 'instagram',
      icon: 'instagram',
      profileUrl: attributes.instagram,
    });
  attributes.facebook &&
    socialMedia.push({
      name: 'facebook',
      icon: 'facebook',
      profileUrl: attributes.facebook,
    });
  attributes.twitter &&
    socialMedia.push({
      name: 'twitter',
      icon: null,
      profileUrl: attributes.twitter,
    });

  return {
    defaultCurrency: attributes.default_currency ?? '',
    logoUrl: attributes.logo,
    defaultLocale: attributes.default_locale ?? '',
    supportedCurrencies: attributes.supported_currencies?.split(',') ?? [],
    supportedLocales: attributes.supported_locales?.split(',') ?? [],
    contactPhone: attributes.contact_phone,
    name: attributes.name ?? 'YES Jewelry',
    description: attributes.description ?? '',
    seoTitle: attributes.seo_title ?? attributes.name ?? '',
    metaDescription: attributes.meta_description ?? attributes.description ?? '',
    address: attributes.address,
    customerSupportEmail: attributes.customer_support_email,
    socialMedia,
  };
}

export type Store = {
  defaultLocale: string;
  defaultCurrency: string;
  description: string;
  supportedLocales: string[];
  supportedCurrencies: string[];
  logoUrl: string | null;
  contactPhone: string | null;
  seoTitle: string;
  metaDescription: string;
  address: string | null;
  customerSupportEmail: string | null;
  socialMedia: {
    name: string;
    icon: SvgIcons | null;
    profileUrl: string;
  }[];
  name: string;
};
