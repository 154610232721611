import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { isDevMode } from '@angular/core';

if (
  !isDevMode() &&
  environment.host &&
  !environment.host?.startsWith('http://localhost')
) {
  Sentry.init({
    dsn: 'https://a5f0a9a86e0b6773d1dae41d58a85e86@o4506297755762688.ingest.sentry.io/4506297762185216',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: [environment.host],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
