import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, switchMap } from 'rxjs';
import routes from '../services/spree-client/storefront/routes';

export const tokenRefresherInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthenticationService);

  if (isOauthRequest(req) || !auth.shouldRefreshToken()) {
    return next(req);
  }

  return auth.refreshAccessToken().pipe(
    catchError((e) => {
      auth.logout();
      throw e;
    }),
    switchMap((newToken) => next(updateTokenIfNeeded(req, newToken))),
  );
};

function isOauthRequest(req: HttpRequest<unknown>): boolean {
  return (
    req.url.endsWith(routes.oauthTokenPath()) ||
    req.url.endsWith(routes.oauthRevokePath())
  );
}

function updateTokenIfNeeded(
  req: HttpRequest<unknown>,
  token: string,
): HttpRequest<unknown> {
  const authorizationHeader = req.headers.get('Authorization');

  if (!authorizationHeader || !authorizationHeader.startsWith('Bearer')) {
    return req;
  }

  return req.clone({
    setHeaders: { Authorization: `Bearer ${token}` },
  });
}
